import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  MailUserFilterAtom,
  MailUserListQuery,
  MailUserTypeAtom,
} from "../../../recoil";
import { overMaxLength } from "../../../utils";

const SearchMemberMenu = ({ open, setOpen }) => {
  const [keyword, setKeyword] = useRecoilState(MailUserFilterAtom);
  const [mailUserType, setMailUserType] = useRecoilState(MailUserTypeAtom);
  const setMailUserList = useSetRecoilState(MailUserListQuery());
  const [mailUserList] = useRecoilState(MailUserListQuery());
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  // 하나라도 체크 안 되어있으면 전체선택 해제
  useEffect(() => {
    let checkValue = true;
    mailUserList.map((mailUser) => {
      let user = { ...mailUser };
      if (user.isSelected === false) {
        checkValue = false;
      }
    });
    setSelectAllCheckbox(checkValue);
  }, [mailUserList, mailUserType]);

  return (
    <>
      {open && (
        <div
          id="search_member"
          className="modal-search popconts"
          style={{
            position: "absolute",
            left: 500,
            top: 54,
            zIndex: 101,
          }}
        >
          <div className="pop-sea pop-02 cf">
            <div className="ptit on" style={{ background: "white" }}>
              <span className="ptit1" style={{ color: "black" }}>
                회원검색
              </span>
              <div className="picon">
                <button
                  type="button"
                  className="pbutton2"
                  onClick={() => setOpen(false)}
                >
                  X
                </button>
              </div>
            </div>
          </div>
          <div className="poimt">
            <div className="psearch">
              <input
                type="text"
                id="search"
                className="search-box"
                placeholder="이름 또는 아이디를 입력하세요."
                onChange={(e) => setKeyword(e.target.value)}
              />
              <br />
              <br />
              <input
                type="checkbox"
                name="chk_all"
                id="chk_all"
                value="전체선택"
                style={{ width: 30 }}
                checked={selectAllCheckbox}
                onChange={(e) => {
                  // setMailUserList({
                  //   selectAll: { option: e.target.checked },
                  // });
                  // setSelectAllCheckbox(e.target.checked);

                  if (selectAllCheckbox === true) {
                    mailUserList.map((mailUser) => {
                      let user = { ...mailUser };
                      setMailUserList({ user });
                    });
                  } else {
                    mailUserList.map((mailUser) => {
                      let user = { ...mailUser };
                      if (user.isSelected === false) {
                        setMailUserList({ user });
                      }
                    });
                  }
                }}
              />
              <label htmlFor="chk_all">전체선택</label>

              <input
                type="radio"
                name="chk_r"
                id="all"
                value="all"
                checked={mailUserType === "all"}
                onChange={(e) => setMailUserType(e.target.value)}
                style={{ width: 30 }}
              />
              <label htmlFor="all">전체</label>
              <input
                type="radio"
                name="chk_r"
                value="company"
                checked={mailUserType === "company"}
                onChange={(e) => setMailUserType(e.target.value)}
                style={{ width: 30 }}
              />
              <label htmlFor="company">기업</label>
              <input
                type="radio"
                name="chk_r"
                id="worker"
                value="employee"
                checked={mailUserType === "employee"}
                onChange={(e) => setMailUserType(e.target.value)}
                style={{ width: 30 }}
              />
              <label htmlFor="worker">근로자</label>
            </div>
            <div
              className="popscroll pop-021"
              id="chat_invite1"
              style={{ maxHeight: 330, overflowY: "auto" }}
            >
              <FilteredMemberList />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const FilteredMemberList = () => {
  const [mailUserList, setMailUserList] = useRecoilState(MailUserListQuery());
  const type = useRecoilValue(MailUserTypeAtom);
  return (
    <>
      {mailUserList
        ?.filter((user) => {
          if (type === "all") {
            return true;
          } else if (type === "company") {
            return user.company === true;
          } else if (type === "employee") {
            return user.company === false;
          }
        })
        .map((user, i) => {
          return (
            <div
              style={{
                backgroundColor: user.isSelected ? "rgb(235, 255, 253)" : "",
              }}
              className="popimg pointer"
              id="people"
              key={i}
              onClick={() => {
                setMailUserList({ user });
              }}
            >
              <a style={{ display: "flex", alignItems: "center" }}>
                <span className="profile"></span>
                <div className="popit">
                  <dl>
                    <dt title={user?.name?.length > 18 ? user?.name : ""}>
                      {overMaxLength(user.name, 18)}
                    </dt>
                    <dt
                      title={
                        user?.companyname?.length > 18 ? user?.companyname : ""
                      }
                    >
                      {overMaxLength(user.companyname, 18)}
                    </dt>
                  </dl>
                </div>
              </a>
            </div>
          );
        })}
    </>
  );
};

export default SearchMemberMenu;

import { useEffect, useState } from "react";
import { DateState, PageInfoState } from "../../../recoil/types/data";
import BreadCrumb from "../../common/BreadCrumb";
import DataTable from "../../common/DataTable";
import PaginationBar from "../../common/PaginationBar";
import SearchByDate from "../../common/SearchByDate";
import SearchCompanyModal from "../../common/SearchCompanyModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import XlsxDownload from "../../form/XlsxDownload";
import { useHolidayActions } from "../../../recoil/api/holiday";
import dayjs from "dayjs";
import Select from "react-select";

const CompanyVacationRules = () => {
  const [toast, setToast] = useRecoilState(toastAtom);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState(PageInfoState);
  const holidayActions = useHolidayActions();
  const [date, setDate] = useState(DateState);
  const [isSearchCompanyModalOpen, setIsSearchCompanyModalOpen] =
    useState(false);
  const [company, setCompany] = useState({});
  const [ruleType, setRuleType] = useState(null);
  const auth = useRecoilValue(authAtom);
  const [ruleList, setRuleList] = useState([]);
  const today = dayjs().format("YYYY-MM-DD");

  const getVacationRules = async () => {
    setLoading(true);
    const res = await holidayActions.getVacationRules({
      pageInfo,
      date,
      COMP_CD: company?.COMP_CD,
      ruleType: ruleType,
    });
    if (res.status === 200) {
      setRuleList(res.data.list);
      setPageInfo(res.data.pageinfo);
    } else {
      setToast({ ...toast, error: true, message: res.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    getVacationRules();
    console.log(date);
  }, [pageInfo?.current, date, company?.COMP_CD, ruleType]);

  return (
    <>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          {/* <div
            className="btn-group btn-group-left"
            style={{ pointerEvents: loading === true ? "none" : "" }}
          >
            <XlsxDownload
              api={`${auth.accountType}/vacation/history`}
              name="연차부여 기록"
              params={{
                COMP_CD: company?.COMP_CD || null,
              }}
            />
          </div> */}
        </div>
        <div className="all-group">
          {/* admin일 경우 기업선택 표시 */}
          {auth.user.isAdmin && (
            <div className="btn-group-inline btn-group-left">
              <button
                className="secondarybtn-md"
                type="button"
                onClick={() => {
                  setIsSearchCompanyModalOpen(true);
                }}
              >
                {company.company_name || "기업 선택"}
              </button>
            </div>
          )}
          <div className="d-inline-block">
            <SearchByDate
              dateAndKeyword={date}
              placeholder="기업명 입력"
              period={30}
              defaultEndtime={dayjs().add(6, "day")}
              select={
                <div className="select-border-line">
                  <Select
                    onChange={(e) => setRuleType(e.value)}
                    isSearchable={false}
                    placeholder="구분"
                    options={[
                      {
                        label: "전체",
                        value: null,
                      },
                      {
                        label: "사용",
                        value: 1,
                      },
                      {
                        label: "예정",
                        value: 2,
                      },
                      {
                        label: "종료",
                        value: 3,
                      },
                    ]}
                  />
                </div>
              }
              onChange={(value) => setDate(value)}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <DataTable
        checkbox={false}
        loading={loading}
        // loadedData={ruleList}
        columns={[
          "기업",
          "구분",
          "적용시작일",
          "부여기준일",
          "회계일",
          "부여일",
          "추가부여기준",
          "1일기준",
          "연차촉진제도",
          "이월여부",
        ]}
      >
        {ruleList?.map((rule, index) => {
          return (
            <tr key={rule.sn}>
              {/* <td className="border-line zoom_font">
                <div className="agree">
                  <label>
                    <input
                      type="checkbox"
                      name="personinfo"
                      onChange={(e) => {
                        onChangeCheckBoxHandler(
                          e.currentTarget.checked,
                          rule.sn
                        );
                      }}
                      checked={checkedItemIds?.includes(rule.sn) ? true : false}
                    />
                    {checkedItemIds?.includes(rule.sn) ? (
                      <div className="chkbox border-line mouse checked"></div>
                    ) : (
                      <div className="chkbox border-line mouse "></div>
                    )}
                  </label>
                </div>
              </td> */}
              {/* <td>{index + 1 + (pageInfo.current - 1) * 8}</td> */}
              <td
                style={{
                  color: rule.active_date > today ? "#22ccb7" : "",
                }}
              >
                {rule.company_name}
              </td>
              <td
                style={{
                  color: rule.active_date > today ? "#22ccb7" : "",
                }}
              >
                {rule.is_active
                  ? "사용"
                  : rule.active_date > today
                  ? "예정"
                  : "종료"}
              </td>
              <td
                style={{
                  color: rule.active_date > today ? "#22ccb7" : "",
                }}
              >
                {dayjs(rule.active_date).format("YYYY-MM-DD")}
              </td>
              <td>{rule.base_date === 0 ? "입사일" : "회계일"}</td>
              <td>{rule.fiscal_date || "-"}</td>
              <td>{rule.grant_day + "일"}</td>
              <td>
                {rule.additional_base_year !== null
                  ? rule.additional_base_year +
                    "년마다 " +
                    rule.additional_grant_day +
                    "일"
                  : "-"}
              </td>
              <td>{rule.day_standard + "일"}</td>
              <td>{rule.is_promotion === 0 ? "사용안함" : "사용"}</td>
              <td>{rule.is_carry === 0 ? "불가능" : "가능"}</td>
            </tr>
          );
        })}
      </DataTable>
      <p style={{ marginTop: 10 }}>{`총 : ${pageInfo?.totalCnt}건`}</p>
      <SearchCompanyModal
        open={isSearchCompanyModalOpen}
        setIsSearchCompanyModalOpen={setIsSearchCompanyModalOpen}
        onChange={(company) => {
          setCompany(company);
        }}
      />
      <PaginationBar
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        loading={loading}
      />
    </>
  );
};

export default CompanyVacationRules;

import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { CHATCONTENTSTYPE } from "../../../JSON/enum/Dataenum";
import { authAtom, ChatListAtom, ChatsAtom, RoomSnAtom } from "../../../recoil";
import { uploadfile } from "../../../recoil/api";
import useChatActions from "../../../recoil/api/chatting";
import { CHAT_VIEW } from "./ChattingLayout";
import arrow from "../../../img/right-arrow.svg";
import xGray from "../../../img/x-gray.svg";

const Chatting = ({ setView, targetList, setFlag }) => {
  const messagesRef = useRef(null);
  const chatSearchRef = useRef([]);

  const [text, setText] = useState("");
  const [isShowMembers, setIsShowMembers] = useState(false);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [chatSearch, setChatSearch] = useState("");
  const [chatSearchIndex, setChatSearchIndex] = useState([]);
  const [chatSearchNow, setChatSearchNow] = useState();

  const auth = useRecoilValue(authAtom);
  const chatList = useRecoilValue(ChatListAtom);
  const [roomSn, setRoomSn] = useRecoilState(RoomSnAtom);
  const setChats = useSetRecoilState(ChatsAtom);

  const chatActions = useChatActions();

  const sendChat = async () => {
    const contents = {
      type: CHATCONTENTSTYPE.TEXT,
      content: text,
    };

    if (roomSn) {
      if (text.length > 0) {
        chatActions.sendChat(roomSn, contents);
      }
    } else {
      const createRoomRes = await chatActions.createRoom(targetList, contents);
      const newChats = {};
      newChats[createRoomRes.room_sn] = createRoomRes;
      setChats((old) => {
        return {
          ...old,
          ...newChats,
        };
      });
      setRoomSn(createRoomRes.room_sn);
    }
    setText("");
  };

  const sendFile = async (file_contents) => {
    if (file_contents.size > 300 * 1024 * 1024) {
      return alert("파일 사이즈는 300MB를 초과 할 수 없습니다.");
    } else {
      const [file, fileRes] = await uploadfile({ file: file_contents });

      const contents = {
        type: CHATCONTENTSTYPE.FILE,
        contents: {
          fileSize: file.size,
          filename: file.name,
          extention: file.name.split(".").pop(),
          url: `${fileRes.data}?alt=media`,
        },
      };
      if (roomSn) {
        chatActions.sendChat(roomSn, contents);
      } else {
        const createRoomRes = await chatActions.createRoom(
          targetList,
          contents
        );
        const newChats = {};
        newChats[createRoomRes.room_sn] = createRoomRes;

        setChats((old) => {
          return {
            ...old,
            ...newChats,
          };
        });
        setRoomSn(createRoomRes.room_sn);
      }
    }
  };

  const scrollToBottom = () => {
    messagesRef.current.scrollIntoView({
      behavior: "auto",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    if (chatSearch === "") {
      scrollToBottom();
    }
  }, [chatList]);

  useEffect(() => {
    if (chatSearch !== "") {
      let searchIndexArr = [];
      let count = 0;
      chatSearchRef.current.findIndex((item) => {
        if (!!item && item.outerText === chatSearch) {
          searchIndexArr.push(count);
          count++;
        } else {
          count++;
        }
      });
      setChatSearchIndex(searchIndexArr);
      setChatSearchNow(searchIndexArr[0]);
    }
  }, [chatSearch]);

  const searchScroll = (type) => {
    if (chatSearch !== "") {
      if (type === "prev") {
        chatSearchRef.current[chatSearchNow].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
        if (chatSearchIndex.length === 0) {
          setChatSearchNow(chatSearchIndex[0]);
        } else if (
          chatSearchIndex.findIndex((item) => item === chatSearchNow) === 0
        ) {
          setChatSearchNow(chatSearchIndex[chatSearchIndex.length - 1]);
        } else {
          setChatSearchNow(
            chatSearchIndex[
              chatSearchIndex.findIndex((item) => item === chatSearchNow) - 1
            ]
          );
        }
      } else {
        chatSearchRef.current[chatSearchNow].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
        if (chatSearchIndex.length === 0) {
          setChatSearchNow(chatSearchIndex[0]);
        } else if (
          chatSearchIndex.findIndex((item) => item === chatSearchNow) ===
          chatSearchIndex.length - 1
        ) {
          setChatSearchNow(chatSearchIndex[0]);
        } else {
          setChatSearchNow(
            chatSearchIndex[
              chatSearchIndex.findIndex((item) => item === chatSearchNow) + 1
            ]
          );
        }
      }
    }
  };
  let titleList = "";
  {
    targetList?.map((user, idx) => {
      return (titleList =
        titleList + `${user.name}(${user.id}_${user.companyname}) `);
    });
  }

  const timeTrans = (time) => {
    const t = new Date(time);
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    return `${time?.slice(0, 10)} (${days[t.getDay()]}요일)`;
  };

  return (
    <>
      <div className="chat-between">
        <div className="flex-start">
          <div
            className="profile user-list pointer"
            onClick={() => setIsShowMembers(!isShowMembers)}
          ></div>
          <div>
            <div
              title={titleList}
              style={{ width: "250px", overflowY: "auto", height: "36px" }}
            >
              {targetList?.map((user, i) => {
                if (user.id !== auth.user.id && user.name !== auth.user.name) {
                  return (
                    <React.Fragment key={i}>
                      <p className="user-name">{`${user.id} (${user.name})`}</p>{" "}
                    </React.Fragment>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="icon-top">
          <button
            className="icon_search"
            onClick={() => {
              setIsShowMembers(false);
              setIsShowSearch(!isShowSearch);
              setChatSearch("");
            }}
          ></button>
          <button
            className="icon_plus"
            onClick={() => {
              setFlag(true);
              setView(CHAT_VIEW.INVITE);
            }}
          ></button>
          <button
            className="icon_out"
            onClick={() => {
              if (window.confirm("퇴장하시겠습니까?")) {
                chatActions.exitRoom(roomSn);
                setView(CHAT_VIEW.ROOM_LIST);
              }
            }}
          ></button>
        </div>
      </div>
      {isShowSearch && (
        <div>
          <input
            style={{ margin: "10px", width: "75%" }}
            className="custom-input"
            type="text"
            value={chatSearch}
            onChange={(e) => {
              setChatSearch(e.target.value);
            }}
          />
          <button
            style={{ marginRight: "5px" }}
            onClick={() => {
              searchScroll("prev");
            }}
          >
            <img src={arrow} style={{ transform: "rotate(-90deg)" }} />
          </button>
          <button
            style={{ marginRight: "5px" }}
            onClick={() => {
              searchScroll("next");
            }}
          >
            <img src={arrow} style={{ transform: "rotate(90deg)" }} />
          </button>
          <button
            onClick={() => {
              setIsShowMembers(false);
              setIsShowSearch(!isShowSearch);
              setChatSearch("");
            }}
          >
            <img src={xGray} />
          </button>
        </div>
      )}
      {isShowMembers && (
        <div
          className="chat-list-all chat-scroll"
          style={{ width: 272, height: 268 }}
        >
          {targetList?.map((user, i) => {
            if (user.id !== auth.user.id && user.name !== auth.user.name) {
              return (
                <p className="user-name" key={i}>
                  {user.id} ({user.name})
                </p>
              );
            }
          })}
        </div>
      )}
      <div
        className="chat-content chat-scroll"
        style={{ height: 308, backgroundColor: "#dfe3e8" }}
      >
        {chatList?.map((chat, index, list) => {
          let contents = {};
          let prevIndex = index !== 0 ? index - 1 : -1;
          let prevCreateTime = list[prevIndex]?.createtime.slice(0, 10);
          let timePoint;
          if (
            prevCreateTime &&
            prevCreateTime !== chat.createtime?.slice(0, 10)
          ) {
            timePoint = timeTrans(chat.createtime);
          } else {
            timePoint = "";
          }
          if (chat.contents) {
            contents = JSON.parse(chat.contents);
          }

          return (
            <div key={chat.chat_index}>
              {contents.type === "IN" && (
                <div style={{ marginTop: 10, textAlign: "center" }}>
                  <p>{contents?.content}</p>
                  <p
                    style={{
                      fontWeight: "800",
                      textAlign: "center",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      margin: "7.5px 0 7.5px 0",
                    }}
                  >
                    {timeTrans(chat?.createtime)}
                  </p>
                </div>
              )}
              {contents.type === "OUT" && (
                <div style={{ marginTop: 10, textAlign: "center" }}>
                  <p>{contents?.content}</p>
                  <p
                    style={{
                      fontWeight: "800",
                      textAlign: "center",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      margin: "7.5px 0 7.5px 0",
                    }}
                  >
                    {timeTrans(chat?.createtime)}
                  </p>
                </div>
              )}
              <p
                style={{
                  fontWeight: "800",
                  textAlign: "center",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  margin: "7.5px 0 7.5px 0",
                }}
              >
                {timePoint !== "" ? timePoint : ""}
              </p>
              {chat.writer_id !== auth.user.id &&
                contents.type !== "IN" &&
                contents.type !== "OUT" && (
                  <div className="flex-start counterpart">
                    <div className="m-width-50">
                      <p className="user-name">
                        {chat.writer_id} {chat.writer_name}
                      </p>
                      {contents.type === CHATCONTENTSTYPE.TEXT && (
                        <div className="chat-cont-text">
                          {chatSearch !== "" &&
                          contents?.content.includes(chatSearch) ? (
                            <>
                              {contents?.content.split(chatSearch).length >=
                              3 ? (
                                <>
                                  <span
                                    ref={(el) =>
                                      (chatSearchRef.current[chat.chat_index] =
                                        el)
                                    }
                                    style={{ backgroundColor: "yellow" }}
                                  >
                                    {chatSearch}
                                  </span>
                                  {contents?.content.substring(
                                    chatSearch.length
                                  )}
                                </>
                              ) : (
                                <>
                                  {contents?.content.split(chatSearch)[0]}
                                  <span
                                    ref={(el) =>
                                      (chatSearchRef.current[chat.chat_index] =
                                        el)
                                    }
                                    style={{ backgroundColor: "yellow" }}
                                  >
                                    {chatSearch}
                                  </span>
                                  {contents?.content.split(chatSearch)[1]}
                                </>
                              )}
                            </>
                          ) : (
                            <p>{contents?.content}</p>
                          )}
                        </div>
                      )}
                      {contents.type === CHATCONTENTSTYPE.FILE && (
                        <p
                          style={{ color: "#9492ea" }}
                          className="chat-cont-text pointer"
                          onClick={() => window.open(contents?.contents?.url)}
                        >
                          [첨부파일]
                          <br />
                          {`${contents.contents.filename}(${
                            contents.contents.fileSize
                              ? `${(
                                  contents.contents.fileSize /
                                  1024 /
                                  1024
                                ).toFixed(2)}MB`
                              : ""
                          })`}
                        </p>
                      )}
                    </div>
                    <div className="timebox">
                      <p>{dayjs(chat.createtime).format("A hh:mm")}</p>
                      {chat.read_cnt > 0 && <p>{chat.read_cnt}</p>}
                    </div>
                  </div>
                )}
              {chat.writer_id === auth.user.id &&
                contents.type !== "IN" &&
                contents.type !== "OUT" && (
                  <div className="flex-end mepart">
                    <div className="timebox">
                      <p>{dayjs(chat.createtime).format("A hh:mm")}</p>
                      {chat.read_cnt > 0 && <p>{chat.read_cnt}</p>}
                    </div>
                    <div className="m-width-50">
                      {contents.type === CHATCONTENTSTYPE.TEXT && (
                        <div className="chat-cont-text">
                          {chatSearch !== "" &&
                          contents?.content.includes(chatSearch) ? (
                            <>
                              {contents?.content.split(chatSearch).length >=
                              3 ? (
                                <>
                                  <span
                                    ref={(el) =>
                                      (chatSearchRef.current[chat.chat_index] =
                                        el)
                                    }
                                    style={{ backgroundColor: "yellow" }}
                                  >
                                    {chatSearch}
                                  </span>
                                  {contents?.content.substring(
                                    chatSearch.length
                                  )}
                                </>
                              ) : (
                                <>
                                  {contents?.content.split(chatSearch)[0]}
                                  <span
                                    ref={(el) =>
                                      (chatSearchRef.current[chat.chat_index] =
                                        el)
                                    }
                                    style={{ backgroundColor: "yellow" }}
                                  >
                                    {chatSearch}
                                  </span>
                                  {contents?.content.split(chatSearch)[1]}
                                </>
                              )}
                            </>
                          ) : (
                            <p>{contents?.content}</p>
                          )}
                        </div>
                      )}
                      {contents.type === CHATCONTENTSTYPE.FILE && (
                        <>
                          <p
                            style={{ color: "#9492ea" }}
                            className="chat-cont-text pointer"
                            onClick={() => window.open(contents?.contents?.url)}
                          >
                            [첨부파일]
                            <br />
                            {`${contents.contents.filename}(${
                              contents.contents.fileSize
                                ? `${(
                                    contents.contents.fileSize /
                                    1024 /
                                    1024
                                  ).toFixed(2)}MB`
                                : ""
                            })`}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
            </div>
          );
        })}
        <div style={{ float: "left", clear: "both" }} ref={messagesRef}></div>
      </div>
      <div className="chatting">
        <div>
          <textarea
            className="textarea"
            style={{ outline: "auto" }}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                e.preventDefault();
                sendChat();
              }
            }}
          ></textarea>
          <button
            href=""
            className="submitbtn-c"
            onClick={() => {
              sendChat();
            }}
          >
            전송
          </button>
        </div>
        <div className="file-clip-box">
          <input
            type="file"
            style={{ display: "none" }}
            id="chat_file"
            onChange={(e) => {
              sendFile(e.target.files[0]);
            }}
          />
          <label className="file-clip" htmlFor="chat_file"></label>
        </div>
      </div>
    </>
  );
};

export default Chatting;

import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { Ellipse } from "react-konva";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { useRecoilValue } from "recoil";
import { ACCOUNTTYPE } from "../../../JSON/enum/account";
import { authAtom } from "../../../recoil";
import useScheduleActions from "../../../recoil/api/schedule";
import { dateUtils, getKeyByValue } from "../../../utils";
import CommonModal from "../../modal/CommonModal";
import BreadCrumb from "../BreadCrumb";
import ScheduleDetailTable from "./ScheduleDetailTable";
import ScheduleRegisteration from "./ScheduleRegistration";
import Select from "react-select";
import ScheduleRegisterationDetail from "./ScheduleRegistrationDetail";
import SearchKeyword from "../SearchKeyword";
import ScheduleFunction from "./ScheduleFunction";
import CommonModalScrollTop from "../../modal/CommonModalScrollTop";

const Schedule = () => {
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  const scheduleActions = useScheduleActions();
  const [isEventDetailModalOpen, setIsEventDetailModalOpen] = useState(false);
  const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [eventDetail, setEventDetail] = useState({});
  const [date, setDate] = useState(dateUtils.startEndOfMonthObj());
  const [scheduleList, setScheduleList] = useState({});
  const [loading, setLoading] = useState(true);
  const [scheduleDetailDate, setScheduleDetailDate] = useState({});
  const [type, setType] = useState(null);
  const [keyword, setKeyword] = useState("");
  const scFn = ScheduleFunction();

  const getCalendar = async (date) => {
    setLoading(true);
    const res = await scheduleActions.getCalendar(date);
    if (res.status === 200) {
      setScheduleList(res.data.scheduleList);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCalendar({ starttime: date.starttime, endtime: date.endtime });
  }, [date]);

  const onChangeViewMoreModalState = () => {
    setIsViewMoreModalOpen(false);
  };
  const onChangeRegistrationModalState = () => {
    setIsRegistrationModalOpen(false);
    getCalendar({ starttime: date.starttime, endtime: date.endtime });
  };

  return (
    <>
      <style jsx="true">
        {`
          .react-calendar {
            width: 100%;
          }
          .react-calendar__year-view .events,
          .react-calendar__decade-view .events,
          .react-calendar__century-view .events {
            display: none;
          }
          .react-calendar button {
            border: 1px solid #eee;
          }
          .react-calendar__month-view__days {
            min-height: 500px;
            display: grid !important;
            grid-template-columns: repeat(7, 1fr);
            grid-auto-rows: 1fr;
          }
          .react-calendar__navigation {
            width: 100%;
          }
          .react-calendar button {
            padding: 0;
          }
          .events {
            width: 100%;
          }
          .event {
            padding: 3px;
            display: flex;
            justify-content: space-between;
            text-align: center;
          }
          .event-1 {
            background: #e0e2fd;
          }
          .event-2 {
            background: #ffb6d7;
          }
          .event-3 {
            background: #ffd5be;
          }
          .event-4 {
            background: #ffd5be;
          }
          .event-5 {
            background: #ffd5be;
          }
          .event-6 {
            background: #fff0ba;
          }
          .event-7 {
            background: #affbba;
          }
          .event-8 {
            background: #affbba;
          }
          .event-9 {
            background: #ffd5be;
          }
          .event-10 {
            background: #ffd5be;
          }
          .event-11 {
            background: #ffd5be;
          }
          .event-12 {
            background: #be88d0;
          }
          .event-13 {
            background: #bdfffe;
          }
          .event-14 {
            background: #9ee0ff;
          }
          .event-holiday {
            background: #d10000;
            color: white;
          }
          .event:hover {
            background: #22ccb7;
          }
          .react-calendar__tile {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            overflow: hidden !important;
          }
          .react-calendar__tile--now {
            color: black;
            background: none;
            border-radius: 0px;
          }
          .react-calendar__viewContainer {
            border: 1px solid #eee;
          }
          .react-calendar__tile--active {
            background: #e6e6e6 !important;
          }
          .view-more {
            background: white;
            color: black;
            padding: 3px;
          }
          .format-day {
            display: flex;
            justify-content: space-between;
            margin: 4px;
          }
          .red-ball {
            background-color: #d10000;
            border-radius: 50%;
            padding: 5px;
          }
          .green-ball {
            background-color: #22ccb7;
            border-radius: 50%;
            padding: 5px;
          }
          .national-font {
            font-weight: bold;
            color: #eee;
          }
          .national-font2 {
            font-weight: bold;
            color: #d10000;
          }
          .daily-font {
            font-weight: bold;
            color: #eee;
          }
          .high-light {
            border: 2px solid #22ccb7 !important;
          }
          .react-calendar__tile--now::before {
            display: none;
          }
          .react-calendar__tile--now > abbr {
            position: static;
            transform: none;
            z-index: 1;
          }
        `}
      </style>
      <BreadCrumb />
      <div className="date-filtering">
        <div className="all-group">
          <button
            type="button"
            className="holidaybtn greenbtn zoom_font border-line"
            onClick={() => {
              navigate(
                `/${getKeyByValue(
                  ACCOUNTTYPE,
                  auth.user.accountType
                ).toLowerCase()}/schedule_list`
              );
            }}
          >
            리스트
          </button>
          <button
            className="holidaybtn greenbtn zoom_font border-line"
            onClick={() => setIsRegistrationModalOpen(true)}
          >
            일정등록
          </button>
        </div>
        <div>
          <div className="all-group" style={{ display: "flex" }}>
            <div
              className="btn-group select-border-line"
              style={{ margin: "0 10px 0 0" }}
            >
              <Select
                placeholder="전체"
                options={scFn.scheduleListOptions()}
                onChange={(e) => setType(e.value)}
              />
            </div>
            <div>
              <SearchKeyword placeholder="이름 입력" setKeyword={setKeyword} />
            </div>
          </div>
        </div>
      </div>
      <Calendar
        calendarType="US"
        minDate={dateUtils.minDate()}
        maxDate={dateUtils.maxDate()}
        prevLabel={"<"}
        nextLabel={">"}
        allowPartialRange={false}
        onActiveStartDateChange={(e) => {
          const thisMonth = dayjs(e.activeStartDate);
          setDate(dateUtils.startEndOfMonthObj(thisMonth));
        }}
        tileClassName={({ date, view }) =>
          (view === "month" &&
            scheduleList[dayjs(date).format("YYYYMMDD")]?.schedule?.some(
              (item) => item.scheduleType === type
            )) ||
          (view === "month" &&
            scheduleList[dayjs(date).format("YYYYMMDD")]?.schedule?.some(
              (item) => {
                return !!keyword && item?.name?.includes(keyword);
              }
            ))
            ? "high-light"
            : null
        }
        showNavigation={true}
        formatDay={(locale, date) => {
          return (
            <>
              <div
                className={`format-day ${
                  new Date().toLocaleDateString() ===
                  new Date(date).toLocaleDateString()
                    ? "daily-font"
                    : ""
                }`}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  className={`format-day ${
                    new Date().toLocaleDateString() ===
                    new Date(date).toLocaleDateString()
                      ? "green-ball"
                      : ""
                  } ${
                    scheduleList[dayjs(date).format("YYYYMMDD")]?.national[0]
                      ? "red-ball"
                      : ""
                  }`}
                >
                  <div
                    className={`${
                      scheduleList[dayjs(date).format("YYYYMMDD")]?.national[0]
                        ? "national-font"
                        : ""
                    } `}
                  >
                    {`${dayjs(date).format("DD")}`}
                  </div>
                </div>
                <div
                  className={`format-day ${
                    scheduleList[dayjs(date).format("YYYYMMDD")]?.national[0]
                      ? "national-font2"
                      : ""
                  }`}
                >
                  {
                    scheduleList[dayjs(date).format("YYYYMMDD")]?.national[0]
                      ?.holiday_name
                  }
                </div>
              </div>
            </>
          );
        }}
        tileContent={({ date }) => {
          const targetDate = dayjs(date).format("YYYYMMDD");
          const events = scheduleList[targetDate];
          return (
            <>
              {loading && (
                <div>
                  <BarLoader />
                </div>
              )}
              {!loading && events && (
                <div className="events">
                  <Events
                    date={targetDate}
                    events={events}
                    setIsEventDetailModalOpen={setIsEventDetailModalOpen}
                    setIsViewMoreModalOpen={setIsViewMoreModalOpen}
                    setEventDetail={setEventDetail}
                    setScheduleDetailDate={setScheduleDetailDate}
                  />
                </div>
              )}
            </>
          );
        }}
      />

      <CommonModal
        open={isEventDetailModalOpen}
        setOpen={setIsEventDetailModalOpen}
        title="일정 보기"
      >
        {eventDetail && (
          <ScheduleRegisterationDetail
            eventDetail={eventDetail}
            setOpen={setIsEventDetailModalOpen}
          />
        )}
      </CommonModal>

      <CommonModal
        open={isViewMoreModalOpen}
        onChangeModalState={onChangeViewMoreModalState}
        title="더보기"
      >
        <ScheduleDetailTable
          date={scheduleDetailDate}
          maxLength={15}
          onChangeModalState={onChangeRegistrationModalState}
        />
      </CommonModal>

      <CommonModalScrollTop
        open={isRegistrationModalOpen}
        onChangeModalState={onChangeRegistrationModalState}
        title="일정 등록"
      >
        <ScheduleRegisteration
          onChangeModalState={onChangeRegistrationModalState}
        />
      </CommonModalScrollTop>
    </>
  );
};

const Events = ({
  date,
  events,
  setIsEventDetailModalOpen,
  setIsViewMoreModalOpen,
  setEventDetail,
  setScheduleDetailDate,
}) => {
  // const nationalLength = events?.national?.length;
  const companyLength = events?.company?.length;

  const scFn = ScheduleFunction();

  return (
    <>
      <div>
        {events?.company?.map((event, i) => {
          return (
            <div key={i}>
              <div>
                <div className={`event event-holiday`}>
                  {event.holiday_name}
                </div>
              </div>
            </div>
          );
        })}
        {events?.schedule?.map((event, i) => {
          return (
            <div key={i}>
              {companyLength + i < 3 ? (
                <div>
                  <div
                    className={`event event-${event.scheduleType}`}
                    onClick={() => {
                      setScheduleDetailDate({
                        starttime: date,
                        endtime: date,
                      });
                      setIsViewMoreModalOpen(true);
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: Ellipse,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {scFn.getScheduleType(event.scheduleType)}
                    </div>
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: Ellipse,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {event.name}
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          );
        })}
        {events?.schedule.length + companyLength > 3 ? (
          <div
            className="view-more"
            onClick={() => {
              setScheduleDetailDate({
                starttime: date,
                endtime: date,
              });
              setIsViewMoreModalOpen(true);
            }}
          >
            +{events?.schedule.length + companyLength - 3}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default Schedule;

import { atom, selector, selectorFamily } from "recoil";
import { get as getQuery, getPage } from "./api";
import {
  CompanyRegisterInitialState,
  ElecLabelState,
  ElecTextareaState,
  LoginInitialState,
  SavedIdlState,
  PageInfoState,
  PaymentsInitialState,
  ToastState,
} from "./types/data";
import { useUserActions } from "./api/auth";

export const DEFAILT_PAGE_COUNT = 8;

export const CompanyRegister = atom({
  key: "CompanyUser",
  default: CompanyRegisterInitialState,
});

export const LoginAlert = atom({
  key: "LoginAlert",
  default: 0,
});

export const LoginInfo = atom({
  key: "LoginInfo",
  default: LoginInitialState,
});

export const SavedId = atom({
  key: "SavedId",
  default: SavedIdlState,
});

export const authAtom = atom({
  key: "auth",
  default: JSON.parse(localStorage.getItem("user")),
});

export const MobileSideShowAtom = atom({
  key: "MobileSideShow",
  default: false,
});

export const PaymentsAtom = atom({
  key: "Payment",
  default: PaymentsInitialState,
});

export const menuAtom = atom({
  key: "menu",
  default: JSON.parse(localStorage.getItem("user"))?.menu || [],
});

export const UpdateMenuSettingState = atom({
  key: "updateMenuSettingState",
  default: 0,
});

export const MenuSettingQuery = selectorFamily({
  key: "menuSettingQuery",
  get:
    ({ id }) =>
    async ({ get }) => {
      get(UpdateMenuSettingState);
      const userActions = useUserActions();
      const auth = get(authAtom);
      const res = await getQuery(`${auth.accountType}/home/menu`, { id });
      if (res.status === 200) {
        if (res.data.length === 0) {
          const res2 = await getQuery(`${auth.accountType}/home/menu`, { id });
          if (res2.status === 200) return res2.data;
        }
        return res.data;
      } else {
        if (window.confirm("불러오기 오류\n새로 고침 하시겠습니까?")) {
          window.location.reload();
        } else {
          return userActions.logout();
        }
      }
    },
});

export const toastAtom = atom({
  key: "toast",
  default: ToastState,
});

export const SearchCompanyModalAtom = atom({
  key: "SearchCompany",
  default: false,
});

//elec 시작
export const ElecLabelAtom = atom({
  key: "ElecLabel",
  default: ElecLabelState,
});

export const ElecPageInfoAtom = atom({
  key: "ElecPageInfo",
  default: PageInfoState,
});

export const ElecTextareaAtom = atom({
  key: "ElecTextarea",
  default: ElecTextareaState,
});

export const ElecEsnAtom = atom({
  key: "ElecEsn",
  default: null,
});

export const ElecURLAtom = atom({
  key: "ElecURL",
  default: null,
});

export const ElecBase64ArrayAtom = atom({
  key: "ElecBase64Arr",
  default: [],
});
//elec 끝

//chatting 시작
export const ChatViewAtom = atom({
  key: "chatView",
  default: 0,
});
export const ChatModalAtom = atom({
  key: "ChatModal",
  default: false,
});

export const RoomSnAtom = atom({
  key: "RoomSn",
  default: null,
});

export const ChatsAtom = atom({
  key: "chat",
  default: {},
});

export const ChatListAtom = atom({
  key: "chatList",
  default: [],
});

export const UserListAtom = atom({
  key: "UserList",
  default: [],
});

export const DocsTypeListAtom = atom({
  key: "DocsTypeList",
  default: [],
});

export const RoomListAtom = atom({
  key: "RoomList",
  default: [],
});

//chatting 끝
export const AccessibilibyAtom = atom({
  key: "accessibility",
  default: {
    zoom: false,
    pointer: false,
    grayPointer: false,

    guideLine: false,
    mask: false,
    zoomLetter: false,

    allArea: false,
    selectedArea: false,
    grayscale: false,
  },
});

export const ApprovalDetailUpdateAtom = atom({
  key: "ApprovalDetailUpdateAtom",
  default: 0,
});
export const ApprovalSnAtom = atom({
  key: "ApprovalSnAtom",
  default: 0,
});

export const ApprovalDetailModalAtom = atom({
  key: "ApprovalDetailModalAtom",
  default: false,
});

// export const ApprovalDetailQuery = selector({
//   key: "ApprovalDetail",
//   get: async ({ get }) => {
//     const sn = get(ApprovalSnAtom);
//     const auth = get(authAtom);
//     const res = await getQuery(`${auth.accountType}/approval/doc`, {
//       sn,
//     });
//     return res.data;
//   },
// });

// export const EmployeeCompanyStampQuery = selector({
//   key: "EmployeeCompanyStamp",
//   get: async ({ get }) => {
//     const auth = get(authAtom);
//     const res = await getQuery("sign/company", {
//       COMP_CD: auth.COMP_CD,
//     });
//     return res.data;
//   },
// });

// export const DocListQuery = selectorFamily({
//   key: "DocList",
//   default: [],
//   get:
//     ({ keyword, pageInfo }) =>
//     async ({ get }) => {
//       const auth = get(authAtom);
//       get(DocListUpdateAtom);
//       let params = {
//         keyword,
//         pageInfo,
//       };

//       return getPage(`${auth.accountType}/innerdoc`, params)
//         .then((res) => {
//           return res.data;
//         })
//         .catch((err) => {
//           throw err;
//         });
//     },
// });

export const DocListUpdateAtom = atom({
  key: "DocListUpdateAtom",
  default: 0,
});

//쪽지
export const MailViewAtom = atom({
  key: "MailViewAtom",
  default: 0,
});

export const MailModalAtom = atom({
  key: "MailModalAtom",
  default: false,
});

export const MailSearchWordAtom = atom({
  key: "MailSearchWordAtom",
  default: "",
});

export const SentMailListQuery = selectorFamily({
  key: "SentMailList",
  default: {},
  get:
    ({ keyword, pageInfo }) =>
    async ({ get }) => {
      const auth = get(authAtom);
      get(MailListUpdateAtom);
      let params = {
        keyword,
        pageInfo,
        count: 10,
      };

      params.id = auth.user.id;

      return getPage("note/send", params)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const ReceivedMailListQuery = selectorFamily({
  key: "ReceivedMailList",
  default: {},
  get:
    ({ keyword, pageInfo }) =>
    async ({ get }) => {
      const auth = get(authAtom);
      get(MailListUpdateAtom);
      let params = {
        keyword,
        pageInfo,
        count: 10,
      };

      params.id = auth.user.id;

      return getPage("note/receive", params)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const ReservationMailListQuery = selectorFamily({
  key: "ReservationMailList",
  default: {},
  get:
    ({ keyword, pageInfo }) =>
    async ({ get }) => {
      const auth = get(authAtom);
      get(MailListUpdateAtom);
      let params = {
        keyword,
        pageInfo,
        count: 10,
      };

      params.id = auth.user.id;

      return getPage("note/reserved", { ...params })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const DetailSnAtom = atom({
  key: "DetailSnAtom",
  default: 0,
});

export const DetailMailQuery = selectorFamily({
  key: "DetailMail",
  default: {},
  get:
    ({ open, readType }) =>
    async ({ get }) => {
      if (open) {
        const sn = get(DetailSnAtom);
        if (readType !== undefined) {
          // 받은쪽지 조회
          return getQuery("note/select", { sn, readType })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              console.log(err);
              throw err;
            });
        } else {
          // 보낸쪽지 조회
          return getQuery("note/select", { sn })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              console.log(err);
              throw err;
            });
        }
      }
    },
});

export const DetailReservationMailQuery = selectorFamily({
  key: "DetailReservationMail",
  default: {},
  get:
    () =>
    async ({ get }) => {
      const sn = get(DetailSnAtom);
      if (sn !== null)
        return getQuery("note/reserved/select", { sn })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.log(err);
            throw err;
          });
    },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const MailUserFilterAtom = atom({
  key: "MailUserFilterAtom",
  default: "",
});

export const MailUserTypeAtom = atom({
  key: "MailUserTypeAtom",
  default: "all",
});

export const MailUserListQuery = selectorFamily({
  key: "MailMemberList",
  default: [],
  get:
    () =>
    async ({ get }) => {
      const UserType = get(MailUserTypeAtom);
      const key = get(MailUserFilterAtom);
      const userList = get(UserListAtom);
      return userList
        .filter((user) => {
          return (
            user.name.includes(key) ||
            user.id?.includes(key) ||
            user.companyname?.includes(key)
          );
        })
        .filter((user) => {
          return (
            UserType === "all" ||
            (user.company === true && UserType === "company") ||
            (user.company === false && UserType === "employee")
          );
        })
        .map((user) => {
          return {
            ...user,
          };
        });
    },

  set:
    () =>
    ({ set }, { user = {}, reset, selectAll }) => {
      set(UserListAtom, (prevState) => {
        if (reset) {
          return prevState.map((state) => {
            return { ...state, isSelected: false };
          });
        }

        const valid = (state, user) => {
          if (user.id && user.id === state.id && user.name === state.name)
            return true;
          if (user.id && user.id === state.id && user.name === state.name)
            return true;
          if (user.id && user.id === state.id && user.name === state.name)
            return true;
          return false;
        };

        if (selectAll) {
          return prevState.map((state) => {
            return { ...state, isSelected: selectAll.option };
          });
        }

        return prevState.map((state) => {
          return valid(state, user)
            ? { ...state, isSelected: !state.isSelected }
            : state;
        });
      });
    },
});

export const DocsUserListQuery = selectorFamily({
  key: "DocsMemberList",
  default: [],
  get:
    () =>
    async ({ get }) => {
      const UserType = "employee";
      const key = get(MailUserFilterAtom);
      const userList = get(UserListAtom);
      return userList
        .filter((user) => {
          return (
            user.name.includes(key) ||
            user.id?.includes(key) ||
            user.companyname?.includes(key)
          );
        })
        .filter((user) => {
          return user.company === false && UserType === "employee";
        })
        .map((user) => {
          return {
            ...user,
          };
        });
    },

  set:
    () =>
    ({ set }, { user = {}, reset, selectAll }) => {
      set(UserListAtom, (prevState) => {
        if (reset) {
          return prevState.map((state) => {
            return { ...state, isSelected: false };
          });
        }

        const valid = (state, user) => {
          if (user.id && user.id === state.id && user.name === state.name)
            return true;
          if (user.id && user.id === state.id && user.name === state.name)
            return true;
          if (user.id && user.id === state.id && user.name === state.name)
            return true;
          return false;
        };

        if (selectAll) {
          return prevState.map((state) => {
            return { ...state, isSelected: selectAll.option };
          });
        }

        return prevState.map((state) => {
          return valid(state, user)
            ? { ...state, isSelected: !state.isSelected }
            : state;
        });
      });
    },
});

export const MailListUpdateAtom = atom({
  key: "MailListUpdateAtom",
  default: 0,
});

export const UnreadMailCntQuery = selectorFamily({
  key: "UnreadMailCntQuery",
  get:
    () =>
    async ({ get }) => {
      const auth = get(authAtom);
      get(MailListUpdateAtom);
      let params = {};

      params.id = auth.user.id;

      const res = await getQuery("note/read", params);

      if (res.status === 200) {
        return res?.data?.cnt;
      } else {
        return 0;
      }
    },
});

export const AgencyLogo = selectorFamily({
  key: "AgencyLogo",
  get:
    () =>
    async ({ get }) => {
      const auth = get(authAtom);
      let params = {};

      params.COMP_CD = auth.user?.COMP_CD;

      const res = await getQuery("agency", params);

      if (res.status === 200) {
        return res?.data;
      } else {
        return null;
      }
    },
});

import { MemberList } from "./MemberList";
import X from "../../../img/x.svg";
import Back from "../../../img/back.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  authAtom,
  ChatListAtom,
  ChatModalAtom,
  ChatsAtom,
  ChatViewAtom,
  RoomListAtom,
  RoomSnAtom,
  UserListAtom,
} from "../../../recoil";
import { useEffect, useState } from "react";
import Chatting from "./Chatting";
import { socket } from "../../../socket";
import { SOCKETENUM } from "../../../JSON/enum/Dataenum";
import Draggable from "react-draggable";
import RoomList from "./RoomList";
import dayjs from "dayjs";
import useChatActions from "../../../recoil/api/chatting";
import InviteChat from "./InviteChat";

export const CHAT_VIEW = {
  MEMBER_LIST: 0,
  CHATTING: 1,
  ROOM_LIST: 2,
  INVITE: 3,
};

const getChattingStatus = (view) => {
  switch (view) {
    case CHAT_VIEW.MEMBER_LIST:
      return "회원목록";
    case CHAT_VIEW.CHATTING:
      return "채팅";
    case CHAT_VIEW.ROOM_LIST:
      return "채팅목록";
    default:
      return "채팅생성";
  }
};

export const ChattingLayout = () => {
  const [chatList, setChatList] = useRecoilState(ChatListAtom);
  const [userList, setUserList] = useRecoilState(UserListAtom);
  const [roomList, setRoomList] = useRecoilState(RoomListAtom);
  const [chats, setChats] = useRecoilState(ChatsAtom);
  const roomSn = useRecoilValue(RoomSnAtom);
  const [chatInput, setChatInput] = useState({});
  const [readMsg, setReadMsg] = useState([]);
  const chatActions = useChatActions();
  const chatModalOpen = useRecoilValue(ChatModalAtom);
  const setChatModalOpen = useSetRecoilState(ChatModalAtom);
  const [view, setView] = useRecoilState(ChatViewAtom);
  const [targetList, setTargetList] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    //상대방 메세지 수신 완료됨
    socket.on(SOCKETENUM.RECEIVE_MESSAGE, async (msg) => {
      await chatActions.updateRoomList(msg.room_sn);
      setChatInput(msg);
    });

    socket.on(SOCKETENUM.CREATEROOMDONE, async (msg) => {
      await chatActions.updateRoomList();
    });

    socket.on(SOCKETENUM.INVITEUSER, async (msg) => {
      await chatActions.updateRoomList();
    });

    socket.on(SOCKETENUM.EXITROOM, async (msg) => {
      chatActions.updateRoomList().then(async () => {
        await chatActions.getChatList(msg.room_sn);
      });
    });

    //나의 메세지 전송 완료됨
    socket.on(SOCKETENUM.SEND_MESSAGE_DONE, async (msg) => {
      setChatInput(msg);
    });

    //채팅방 목록 가져오기
    socket.on(SOCKETENUM.CHATROOMLIST, async (res) => {
      console.log(JSON.parse(res.roomList).roomList);
      await setRoomList(JSON.parse(res.roomList).roomList);
      setUserList(JSON.parse(res.userList));
      //해당 방의 채팅 목록 가져오기
      socket.on(SOCKETENUM.CHATLIST, async (msg) => {
        const newChats = {};
        newChats[msg.room_sn] = msg;
        setChats((old) => {
          return {
            ...old,
            ...newChats,
          };
        });
      });
    });

    //새 방 목록 가져오기
    socket.on(SOCKETENUM.REQUESTCHATROOM, (res) => {
      setRoomList((old) => {
        if (old.filter((room) => room.room_sn === res.room_sn).length === 0) {
          return [...old, JSON.parse(res.roomInfo)];
        }
        return old;
      });
    });

    //Read 이벤트
    socket.on(SOCKETENUM.READ_MESSAGE, async (msg) => {
      await chatActions.updateRoomList(msg.room_sn);
      setReadMsg(msg.cntList);
    });

    return () => {
      socket.off(SOCKETENUM.RECEIVE_MESSAGE);
      socket.off(SOCKETENUM.SEND_MESSAGE_DONE);
      socket.off(SOCKETENUM.CHATROOMLIST);
      socket.off(SOCKETENUM.CHATLIST);
      socket.off(SOCKETENUM.REQUESTCHATROOM);
      socket.off(SOCKETENUM.READ_MESSAGE);
    };
  }, []);

  useEffect(() => {
    //새로운 채팅방이 생성되면 채팅방 목록 가져옴
    if (
      roomList.filter((room) => room.room_sn === chatInput.room_sn).length === 0
    ) {
      chatActions.getRoomList(chatInput.room_sn);
    }
    //채팅중이면 readMessage 처리
    if (
      chatModalOpen &&
      chatInput.room_sn === roomSn &&
      view === CHAT_VIEW.CHATTING
    ) {
      chatActions.readMessage(roomSn, false);
    }

    //새로운 채팅이 도착하면 chats에 추가
    if (chats[roomSn] && Object.keys(chatInput).length !== 0) {
      const contents = JSON.stringify(chatInput.contents);
      //chat input을 content 객체로 생성
      const content = {
        read_cnt:
          chatList.length === 0
            ? chatList?.[chatList.length - 1]?.read_cnt
            : chatInput.cntList[0].read_cnt,
        chat_sn: chatInput.cntList[0].chat_sn,
        createtime: `${dayjs(contents.createtime).format(
          "YYYY-MM-DD HH:mm:ss"
        )}`,
        chat_index: chatInput.chatIndex,
        writer_id: chatInput.id,
        writer_name: chatInput.name,
        contents: contents,
      };

      //chats 객체를 newChats로 복사
      const newChats = { ...chats };
      let chatListCopy = [];
      if (newChats[chatInput.room_sn]?.chatList) {
        chatListCopy = JSON.parse(newChats[chatInput.room_sn].chatList);
      }
      chatListCopy.push(content);
      const chatListString = JSON.stringify(chatListCopy);

      const nn = {
        ...newChats,
        [chatInput.room_sn]: {
          ...newChats[chatInput.room_sn],
          chatList: chatListString,
        },
      };
      setChats(nn);
    }
  }, [chatInput]);

  //채팅 읽음처리
  useEffect(() => {
    if (readMsg && roomSn) {
      const newChats = { ...chats };
      let newChatList = [];
      let listCopy = [...chatList];
      let msgCopy = [...readMsg];
      if (listCopy.length > 0 && msgCopy.length > 0) {
        for (let i = 0; i < listCopy.length; i++) {
          let chatCopy = { ...listCopy[i] };
          for (let j = 0; j < msgCopy.length; j++) {
            if (chatCopy.chat_sn === msgCopy[j].chat_sn) {
              chatCopy.read_cnt = msgCopy[j].read_cnt;
            }
          }
          newChatList.push(chatCopy);
        }
      }
      setChatList(newChatList);
      const chatListString = JSON.stringify(newChatList);
      const nn = {
        ...newChats,
        [chatInput.room_sn]: {
          ...newChats[chatInput.room_sn],
          chatList: chatListString,
        },
      };
      setChats(nn);
    }
  }, [readMsg]);

  useEffect(() => {
    if (chats[roomSn]) {
      if (chats[roomSn].chatList) {
        setChatList(JSON.parse(chats[roomSn].chatList));
      }
    }
  }, [chats, roomSn]);

  useEffect(() => {
    if (chatModalOpen) {
      setView(CHAT_VIEW.MEMBER_LIST);
    }
  }, [chatModalOpen]);

  return (
    <Draggable
      bounds={{
        top: 0,
        left: -(window.document.querySelector("#root").clientWidth - 500),
        right: 0,
        bottom: window.document.querySelector("#root").clientHeight - 600,
      }}
      handle=".chat-top"
    >
      <div
        className="chat-box"
        style={{ display: chatModalOpen ? "block" : "none" }}
      >
        <div className="chat-top">
          <div>{getChattingStatus(view)}</div>
          <div>
            <button onClick={() => setView(CHAT_VIEW.ROOM_LIST)}>
              <img src={Back} alt="되돌아가기" />
            </button>

            <button
              onClick={() => {
                setChatModalOpen(false);
              }}
            >
              <img src={X} alt="닫기" />
            </button>
          </div>
        </div>
        {view === CHAT_VIEW.MEMBER_LIST && (
          <MemberList
            setView={setView}
            setTargetList={setTargetList}
            setFlag={setFlag}
          />
        )}
        {view === CHAT_VIEW.CHATTING && (
          <Chatting
            setView={setView}
            targetList={targetList}
            setFlag={setFlag}
          />
        )}
        {view === CHAT_VIEW.ROOM_LIST && (
          <RoomList setTargetList={setTargetList} setFlag={setFlag} />
        )}
        {view === CHAT_VIEW.INVITE && (
          <InviteChat setTargetList={setTargetList} flag={flag} />
        )}
      </div>
    </Draggable>
  );
};

import { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  authAtom,
  MailListUpdateAtom,
  MailUserListQuery,
  MailViewAtom,
  toastAtom,
  UserListAtom,
} from "../../../recoil";
import SearchMemberMenu from "./SearchMemberMenu";
import { Editor } from "@tinymce/tinymce-react";
import { useForm, Controller } from "react-hook-form";
import { useMailActions } from "../../../recoil/api/mail";
import { useEffect } from "react";
import dayjs from "dayjs";
import PopAtta from "../../../img/pop-atta.png";
import { isJson } from "../../../utils";
import { MAIL_VIEW } from "./MailLayout";
import { DetailSnAtom } from "../../../recoil";
import Select from "react-select";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { QuillEditor } from "../../toolbar/QuillEditor";

const SendMail = ({ editMail }) => {
  const auth = useRecoilValue(authAtom);
  const [isReservation, setIsReservation] = useState(false);
  const [searchMemberMenuOpen, setSearchMemberMenuOpen] = useState(true);
  const setMailUserList = useSetRecoilState(MailUserListQuery());
  const mailUserList = useRecoilValue(UserListAtom);
  const setView = useSetRecoilState(MailViewAtom);
  const [toast, setToast] = useRecoilState(toastAtom);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    resetField,
  } = useForm();
  const setDetailSn = useSetRecoilState(DetailSnAtom);
  const [prevEditor, setPrevEditor] = useState(false);
  useEffect(() => {
    if (editMail) {
      setValue("sn", editMail.sn);
      setValue("title", editMail.title);
      setValue("contents", editMail.contents);
      setValue(
        "createtime",
        dayjs(editMail.createtime).format("YYYY-MM-DDTHH:mm:ss")
      );
      setIsReservation(true);
      setMailUserList({ reset: true });

      if (isJson(editMail.file_contents)) {
        setValue("file_contents", JSON.parse(editMail.file_contents));
      }

      for (let user of editMail.des_List) {
        setMailUserList({
          user: {
            ...user,
            name: user.name,
            id: user.id,
          },
        });
      }
    }
  }, [editMail]);

  const mailListUpdate = useSetRecoilState(MailListUpdateAtom);
  const mailActions = useMailActions();

  const onSubmit = async (data) => {
    let source_info = {
      ...auth.user,
    };
    if (auth.user.isAdmin) {
      source_info.id = auth.user.id;
    }
    if (auth.user.isCompany) {
      source_info.id = auth.user.id;
    }
    if (auth.user.isEmployee) {
      source_info.id = auth.user.id;
    }

    let params = {
      ...data,
      sn: editMail ? editMail.sn : "",
      isreserved: isReservation,
      source_info: source_info,
      createtime: null,
      des_List: mailUserList
        .filter((user) => {
          return user.isSelected;
        })
        .map((user) => {
          if (user.id) {
            return {
              ...user,
              id: user.id,
            };
          } else {
            return {
              ...user,
            };
          }
        }),
    };
    if (params?.file_contents) {
      if (params?.file_contents[0]?.size > 300 * 1024 * 1024) {
        return setToast({
          ...toast,
          error: true,
          message: "파일 용량은 300MB를 초과 할 수 없습니다.",
        });
      }
    }
    if (params.des_List.length === 0) {
      return setToast({
        ...toast,
        error: true,
        message: "쪽지 발송할 회원을 선택해 주세요",
      });
    }
    if (params.contents === undefined || params.contents === "") {
      return setToast({
        ...toast,
        error: true,
        message: "쪽지 내용을 입력하세요",
      });
    }
    if (isReservation) {
      if (!data.createtime) {
        return setToast({
          ...toast,
          error: true,
          message: "예약시간을 선택하세요",
        });
      }
      params.createtime = dayjs(data.createtime).format("YYYY-MM-DD HH:mm:ss");
    }

    if (editMail) {
      //예약 쪽지 수정일 경우
      const res = await mailActions.editMail(params);
      if (res.status === 200) {
        setToast({ ...toast, on: true, message: "수정 완료했습니다." });
        mailListUpdate((update) => update + 1);
        reset();
        setMailUserList({ reset: true });
        setView(MAIL_VIEW.RESERVATION_MAIL);
        setDetailSn(null);
      }
    } else {
      const res = await mailActions.sendMail(params);
      if (res.status === 200) {
        setToast({ ...toast, on: true, message: "쪽지 발송완료" });
        mailListUpdate((update) => update + 1);
        reset();
        setMailUserList({ reset: true });
      }
    }
  };

  let select_mailUserList = mailUserList
    ?.filter((user) => {
      return user.isSelected;
    })
    .map((user) => {
      return { value: user.id, label: user.name };
    });

  return (
    <>
      <div id="send_message">
        <div className="poimt wrimess">
          <form name="save_htmlForm" onSubmit={handleSubmit(onSubmit)}>
            <br />
            <div style={{ display: "flex" }}>
              <div style={{ width: "70%", marginRight: "5px" }}>
                <Select
                  className="mail-ellipsis2"
                  placeholder={mailUserList
                    ?.filter((user) => {
                      return user.isSelected;
                    })
                    .map((user) => {
                      return `${user.name} `;
                    })}
                  options={select_mailUserList}
                  value={""}
                ></Select>
              </div>
              <div className="btn-group" style={{ margin: 0 }}>
                <button
                  type="button"
                  className="primarybtn-md"
                  onClick={() => setSearchMemberMenuOpen(true)}
                >
                  회원검색
                </button>
                <button
                  type="button"
                  className="primarybtn-md"
                  onClick={() => {
                    setMailUserList({ reset: true });
                  }}
                >
                  초기화
                </button>
              </div>
            </div>
            <br />
            <div className="search w-100">
              <input
                {...register("title")}
                placeholder="쪽지 제목을 입력해주세요."
                style={{
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
            <br />
            <input
              type="checkbox"
              id="resv"
              style={{ width: 30 }}
              value={isReservation}
              onChange={() => setIsReservation(!isReservation)}
              checked={isReservation}
            />
            <label id="resv_label" htmlFor="resv">
              예약
            </label>
            {auth.user.isAdmin && (
              <>
                <input
                  type="checkbox"
                  id="editor_changer"
                  style={{ width: 30 }}
                  value={prevEditor}
                  onChange={() => setPrevEditor(!prevEditor)}
                  checked={prevEditor}
                />
                <label id="editor_label" htmlFor="editor_changer">
                  기존 에디터
                </label>
              </>
            )}
            <br />
            <div
              className="search w-100"
              style={{
                display: isReservation ? "block" : "none",
              }}
            >
              <input
                {...register("createtime", {})}
                min={dayjs().add(5, "minute").format("YYYY-MM-DDTHH:mm")}
                disabled={!isReservation}
                type="datetime-local"
                style={{
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
            <br />
            <Controller
              name="contents"
              control={control}
              render={({ field: { value, onChange } }) => {
                return prevEditor ||
                  auth.user?.disabled_name == "시각장애" ||
                  auth.user?.sub_disabled_name == "시각장애" ? (
                  <Editor
                    apiKey={"pwlbxd7c7x9lapnd8nwqidzm2gre8bazisoovnitdbojymrp"}
                    value={value}
                    onEditorChange={onChange}
                    init={{
                      plugins: ["table", "link", "autolink", "contextmenu"],
                      language: "ko_KR",
                      selector: "div",
                      elementpath: false,
                      branding: false,
                      autolink: true,
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                      contextmenu: "true",
                      menubar: "file insert view format table",
                    }}
                  />
                ) : (
                  <QuillEditor value={value} onChange={onChange} />
                );
              }}
            />
            <div className="search attat" style={{ display: "flex" }}>
              <input
                {...register("file_contents")}
                type="file"
                id="message_file"
                style={{ display: "none" }}
              />
              <label htmlFor="message_file">
                <img src={PopAtta} />
              </label>
              <label style={{ cursor: "pointer" }} htmlFor="message_file">
                {watch("file_contents")
                  ? watch("file_contents")[0]?.name ||
                    watch("file_contents")?.contents?.filename ||
                    "선택된 파일없음"
                  : "선택된 파일없음"}
              </label>
              {watch("file_contents") && editMail ? (
                <FileDeleteBtn
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    resetField("file_contents", { defaultValue: null })
                  }
                />
              ) : (
                <></>
              )}
            </div>
            <div className="searchcont sea">
              <div className="sendMailbtn">
                <button className="">{editMail ? "수정하기" : "보내기"}</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <SearchMemberMenu
        open={searchMemberMenuOpen}
        setOpen={setSearchMemberMenuOpen}
      />
    </>
  );
};

export default SendMail;
